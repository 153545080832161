//お問い合わせ内容のその他とそのテキストボックス
$(function () {
	if ($("input[name=contentsOther]").val() !== "") {
		$("input[name=contentsOther]").prop("disabled", false);
	}

	$(".contact__radioBox input[type='radio']").on("click", function () {
		if ($(this).val() === "その他") {
			$("input[name=contentsTest]").prop("disabled", true);
			$("input[name=contentsOther]").prop("disabled", false);
		} else if ($(this).val() === "テスト希望") {
			$("input[name=contentsOther]").prop("disabled", true);
			$("input[name=contentsTest]").prop("disabled", false);
		} else {
			$("input[name=contentsOther]").prop("disabled", true);
			$("input[name=contentsTest]").prop("disabled", true);
		}
	});

	//送信ボタンと同意ボタン
	$(".contact__agreeCheckbox input").prop("checked", false);

	$(".contact__agreeCheckbox input").on("click", function () {
		if ($(this).prop("checked")) {
			$(".contact__sendBox button").prop("disabled", false);
		} else {
			$(".contact__sendBox button").prop("disabled", true);
		}
	});
});
